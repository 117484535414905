import React, { useEffect } from 'react';

import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, useOktaAuth } from '@okta/okta-react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import Root from './pages/root';
import ErrorPage from './pages/ErrorPage';
import Unauthorized from './pages/Unauthorized';
import ChooseRawDataExportType from './pages/rise/ChooseRawDataExportType';
import RiseRoot from './pages/rise/RiseRoot';
import RiseReport from './pages/rise/RiseReport';
import { oktaAuthConfig } from './config/oktaAuthConfig';
import SilentAuth from './components/rise/SilentAuth';


const oktaAuth = new OktaAuth(oktaAuthConfig);

const RequireAuth = (props: any) => {
    const navigate = useNavigate();
    const { authState } = useOktaAuth();
    useEffect(() => {

        if (authState && !authState?.isAuthenticated) {
            if (!authState?.isPending) {
                navigate('/rise/unauthorized');
            }
        }
    }, [authState, authState?.isAuthenticated, authState?.isPending, navigate]);

    if (!authState?.isAuthenticated) {
        return null;
    }

    return <>{props.children}</>;
};

const OktaErrorComponent = (props: any) => {
    const { authState, oktaAuth } = useOktaAuth();
    useEffect(() => {
        if (props?.error?.errorCode === 'access_denied') {
            oktaAuth.signOut().then((c: any) => { }).finally(() => {
                oktaAuth.signInWithRedirect();
            });
        }
    }, [authState, oktaAuth, props?.error?.errorCode]);

    return <>{props?.error?.name} {props?.error?.errorSummary}</>;
};

function AppRoutes() {
    const navigate = useNavigate();

    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
        navigate(toRelativeUrl(originalUri || "/", window.location.origin), { replace: true });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <Routes>
                    <Route
                        path="/login/callback"
                        element={<LoginCallback errorComponent={OktaErrorComponent} />}
                    />
                    <Route
                        path="/"
                        element={<Root />}
                        errorElement={<ErrorPage />}
                    />
                    <Route
                        path="/rise"
                        element={<RiseRoot />}
                        errorElement={<ErrorPage />}
                    >
                        <Route errorElement={<ErrorPage />}>
                            <Route index element={<Navigate to="/rise/raw-data-export-type" />} />
                            <Route
                                path="raw-data-export-type"
                                element={<RequireAuth><ChooseRawDataExportType /></RequireAuth>}
                            />
                            <Route
                                path="report/:id"
                                element={<RequireAuth><RiseReport /></RequireAuth>}
                            />
                            <Route path="unauthorized" element={<Unauthorized />} />
                            <Route path="silent-auth" element={<SilentAuth />} />
                        </Route>
                    </Route>
                </Routes>
            </Security>
        </LocalizationProvider>
    );
}

function App() {
    return (
        <BrowserRouter>
            <AppRoutes />
        </BrowserRouter>
    );
}

export default App;
