import { FC, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";

const SilentAuth: FC = () => {
    const { oktaAuth } = useOktaAuth();

    useEffect(() => {
        if (oktaAuth) {
            oktaAuth.signInWithRedirect();
        }
    }, [oktaAuth]);

    return null;
};

export default SilentAuth;