import { Outlet } from "react-router-dom";
import Header from "../../components/rise/Header";
import './RiseRoot.scss';

export default function RiseRoot() {
    return <>
        <Header />
        <div className="rise-body">
            <Outlet />
        </div>
    </>
}