import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from '@mui/material/Tooltip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { getExportTypeListAPI } from "../../../api/chooseRawDataExportTypeAPI";

import './ChooseRawDataExportType.scss';

const ChooseRawDataExportType: FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>();
    const [list, setList] = useState<Array<any>>([]);

    useEffect(() => {
        getExportTypeListAPI().then(res => {
            setList(res);
        }).catch(err => {
            setError(err);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return (
        <div className="raw-data-export-type-body">

            <div className="raw-data-export-type-container">
                {/* <h1 className="export-type-title">Choose raw data export type</h1> */}
                <div className="back-construction" style={{ marginBottom: '30px' }}
                >
                    <a href={`${process.env.REACT_APP_RISE_URL}`}>
                        <Tooltip title="Back to Rise">
                            <ChevronLeftIcon onClick={() => {
                                navigate('/rise/raw-data-export-type');
                            }} className="icon-back" />
                        </Tooltip>
                    </a>
                    <h2>Choose raw data export type</h2>
                </div>

                <div className="raw-data-export-type-items">

                    {list?.map(data => (<div className="raw-data-export-type-item" key={data.id}>
                        <Button className="btn-default-outlined export-type-btn"
                            onClick={() => {
                                navigate(`/rise/report/${data.id}`);
                            }}
                            disableRipple variant="outlined"
                            disabled={!data?.permission}>{data?.title}</Button>

                        {data?.children && <div className="raw-data-export-type-subitems">
                            {data?.children?.map((child: any) => (
                                <Button className="btn-default export-type-btn-sub" key={child.id}
                                    onClick={() => {
                                        navigate(`/rise/report/${child.id}`);
                                    }}
                                    disabled={!child?.permission} disableRipple>
                                    {child?.title}
                                </Button>
                            ))}
                        </div>}
                    </div>))}

                    {
                        loading && (
                            <div style={{ margin: '0 auto', marginTop: '50px' }}>
                                <CircularProgress />
                            </div>)
                    }

                    {
                        error && (
                            <div style={{ margin: '0 auto', marginTop: '50px' }}>
                                <h1 style={{ fontFamily: "Lato, sans-serif", fontSize: '24px' }}>
                                    Something went wrong!
                                </h1>
                            </div>)
                    }

                </div>

            </div>
        </div>
    );
}

export default ChooseRawDataExportType;