import { FC, useState, useEffect } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from "@mui/material/Button";

import { useOktaAuth } from "@okta/okta-react";

import logo from '../../../assets/logo.svg';
import './Header.scss';

const userLetters = (name: string): string => {
    const nameArr = name.split(' ').map(n => n[0]);
    return (nameArr[0] || '') + (nameArr[1] || '');
};

const Header: FC = () => {
    const { oktaAuth, authState } = useOktaAuth();
    const [userInfo, setUserInfo] = useState<any>(null);

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            setUserInfo(authState?.idToken?.claims);
        }
    }, [authState, oktaAuth]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const signOutAction = () => {
        handleClose();
        oktaAuth.signOut();
    };

    return (
        <div className="rise-header">
            <Box sx={{ flexGrow: 1 }}>
                <AppBar style={{ background: '#fff' }} position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            <img src={logo} alt="Majorel" />
                        </Typography>



                        {authState?.isAuthenticated ?
                            <>
                                <Typography className="rise-user-info" style={{ color: '#000', cursor: 'pointer' }}
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}

                                    variant="h6" component="div">
                                    <div className="rise-user-round">
                                    {userLetters(userInfo?.name || '')}
                                    </div>
                                    <p style={{ fontFamily: `'Lato', sans-serif` }}>{userInfo?.name}</p>
                                </Typography>


                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={signOutAction}>Sign Out</MenuItem>
                                </Menu>
                            </> : (
                                <Typography className="user-info"
                                    variant="h6" component="div">
                                    <Button onClick={() => {
                                        oktaAuth.signInWithRedirect();
                                    }}>Sign in</Button>
                                </Typography>
                            )}
                    </Toolbar>
                </AppBar>
            </Box>
        </div>

    );
};

export default Header;
