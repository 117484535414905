import { FC } from "react";
import { Navigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import icon from '../../assets/okta2.png';
import './Unauthorized.scss';

const Unauthorized: FC = () => {
    const { oktaAuth, authState } = useOktaAuth();
    if (authState?.isAuthenticated) {
        return <Navigate to='/' />;
    }
    return <div className="notAuthed-message-box">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 className="login-text">Please click to Login</h1>
            <Button className="login-btn" onClick={() => oktaAuth.signInWithRedirect()}>
                <img src={icon} alt='okta' style={{ width: '60px', height: 'auto' }} /><br />
                Sign in with Okta
            </Button>
        </div>
    </div>;
};

export default Unauthorized;