import request from "./request";

export function maxNumberOfRecordsAPI() {
    return request('/api/v1/Info/maxNumberOfRecords', { method: 'GET' });
}

export function getExportPermissionAPI(id: any) {
    return request(`/api/v1/Permission/userReportPermissions/${id}`, { method: 'GET' });
}

export function getClientListAPI() {
    return request(`/api/v1/Permission/clients`, { method: 'GET' });
}

export function getCountrySiteListAPI(clientId: any) {
    return request(`/api/v1/Permission/countrySites/${clientId}`, { method: 'GET' });
}

export function getLobSubLobListAPI(clientId: any, siteId: any) {
    return request(`/api/v1/Permission/lobSubLobs/${clientId}/${siteId}`, { method: 'GET' });
}

export function downloadReportAPI(requestBody: any, onDownloadProgress: any) {
    return request(`/api/v1/Report`,
        {
            method: 'POST', data: requestBody,
            responseType: 'blob', onDownloadProgress,
        },
        'application/json');
}