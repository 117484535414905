import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";

import { DesktopDatePicker } from "@mui/x-date-pickers";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {
    downloadReportAPI,
    getClientListAPI, getCountrySiteListAPI,
    getExportPermissionAPI, getLobSubLobListAPI, maxNumberOfRecordsAPI
} from "../../../api/riseReportAPI";

import './RiseReport.scss';

const AutoCompleteLoading = () => {
    return (
        <div style={{
            zIndex: 22, marginTop: '-60px', display: 'flex', height: '60px', justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CircularProgress style={{ width: '30px', height: '30px' }} />
        </div>
    )
};

const minimalDate = moment('01-04-2022', "DD-MM-YYYY");
const maximumDate = moment();

const RiseReport: FC = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [downloadPercentage, setDownloadPercentage] = useState(0);
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [heading, setHeading] = useState<any>();
    const [maxNumberOfRecords, setMaxNumberOfRecords] = useState<any>();
    // ---- dates
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);

    // ---- clientList
    const [clientList, setClientList] = useState<any>([]);
    const [currentClientId, setCurrentClientId] = useState<any>();
    // ---- countrySites
    const [countrySiteList, setCountrySiteList] = useState<any>([]);
    const [currentCountrySite, setCurrentCountrySite] = useState<any>();
    const [countrySiteLoading, setCountrySiteLoading] = useState(false);
    // ---- lobSubLobs
    const [lobSubLobList, setLobSubLobList] = useState<any>([]);
    const [currentLobSubLob, setCurrentLobSubLob] = useState<any>();
    const [lobSubLobLoading, setLobSubLobLoading] = useState(false);
    // ---- download
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [downloadError, setDownloadError] = useState(false);


    const onChangeClient = (newValue: any) => {
        setCurrentClientId(newValue?.id);
        setCurrentCountrySite(null);
        setCurrentLobSubLob(null);
    };

    const onChangeCountrySite = (newValue: any) => {
        setCurrentCountrySite(newValue);
        setCurrentLobSubLob(null);
    };

    const onChangeLobSubLob = (newValue: any) => {
        setCurrentLobSubLob(newValue);
    };

    useEffect(() => {
        if (id) {
            Promise
                .allSettled([getExportPermissionAPI(id), maxNumberOfRecordsAPI(), getClientListAPI()])
                .then(([permissions, records, clients]) => {
                    if (permissions?.status === 'fulfilled') {
                        const data = permissions?.value;
                        if (!data?.permission) { navigate('/rise/raw-data-export-type'); }
                        setHeading(data?.title);
                    }

                    if (records?.status === 'fulfilled') {
                        setMaxNumberOfRecords(
                            records?.value?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        );
                    }

                    if (clients?.status === 'fulfilled') {
                        if (Array.isArray(clients?.value)) {
                            const list = clients.value.map(val => ({ label: val.value, id: val.id }));
                            setClientList(list);
                            if (list.length === 1) {
                                onChangeClient(list[0]);
                            }
                        }
                    }

                }).finally(() => setPageLoading(false));
        }
    }, [id]);

    useEffect(() => {
        if (currentClientId) {
            setCountrySiteLoading(true);
            getCountrySiteListAPI(currentClientId).then((res) => {
                if (Array.isArray(res)) {
                    const list = res?.map(val => ({ label: val.value, id: val.id }));
                    setCountrySiteList(list);
                    if (list.length === 1) {
                        onChangeCountrySite(list[0]);
                    }
                }
            }).finally(() => setCountrySiteLoading(false));
        }
    }, [currentClientId]);

    useEffect(() => {
        if (currentClientId && currentCountrySite) {
            setLobSubLobLoading(true);
            getLobSubLobListAPI(currentClientId,
                currentCountrySite?.id).then((res) => {
                    if (Array.isArray(res)) {
                        const list = res?.map(val => ({ label: val.value, id: val.id }));
                        setLobSubLobList(list);
                        if (list.length === 1) {
                            onChangeLobSubLob(list[0]);
                        }
                    }
                }).finally(() => setLobSubLobLoading(false));
        }
    }, [currentClientId, currentCountrySite]);

    const clientMemoizedValue = useMemo(() => {
        return clientList?.find((c: any) => c.id === currentClientId)?.label;
    }, [clientList, currentClientId]);

    const countrySiteMemoizedValue = useMemo(() => {
        return countrySiteList?.find((c: any) => c.id === currentCountrySite?.id)?.label;
    }, [countrySiteList, currentCountrySite]);

    const lobSubLobMemoizedValue = useMemo(() => {
        return lobSubLobList?.find((c: any) => c.id === currentLobSubLob?.id)?.label;
    }, [lobSubLobList, currentLobSubLob]);


    const startDateIsNotValid = useMemo(() => {
        return startDate?.isBefore(minimalDate, 'day') || startDate?.isAfter(maximumDate, 'day') ||
            !startDate?.isValid();
    }, [startDate]);

    const endDateIsNotValid = useMemo(() => {
        return endDate?.isBefore(minimalDate, 'day') || endDate?.isAfter(maximumDate, 'day') || !endDate?.isValid();
    }, [endDate]);

    const disabledExport = useMemo(() => {
        return !startDate || !endDate || !currentClientId ||
            !currentCountrySite || !currentLobSubLob ||
            startDate?.isBefore(minimalDate, 'day') || startDate?.isAfter(maximumDate, 'day') ||
            endDate?.isBefore(minimalDate, 'day') || endDate?.isAfter(maximumDate, 'day') ||
            !startDate?.isValid() || !endDate?.isValid();
    }, [startDate, endDate, currentClientId, currentCountrySite, currentLobSubLob]);
    const Data = (
        <>
            <div className="back-construction" style={{ marginBottom: '10px' }}
            >
                <ChevronLeftIcon onClick={() => {
                    navigate('/rise/raw-data-export-type');
                }} className="icon-back" /><h2>{heading}</h2>
            </div>

            <Card className="card without-border"
                variant="outlined">
                <div style={{ padding: '30px' }}>
                    <Stack spacing={3}>
                        <Stack direction="column" spacing={0}>
                            <Stack direction="row" spacing={2}>
                                <DesktopDatePicker
                                    label="From"
                                    inputFormat="DD.MM.YYYY"
                                    value={startDate}
                                    minDate={minimalDate}
                                    maxDate={maximumDate}
                                    className="input"
                                    InputProps={{ disableUnderline: true }}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                        if (endDate?.isBefore(newValue, 'day')) {
                                            setEndDate(null);
                                        }
                                    }}
                                    renderInput={(params) => <TextField
                                        variant="filled"
                                        {...params}
                                    />}
                                />
                                <DesktopDatePicker
                                    label="To"
                                    inputFormat="DD.MM.YYYY"
                                    value={endDate}
                                    className="input"
                                    minDate={minimalDate}
                                    maxDate={maximumDate}
                                    InputProps={{ disableUnderline: true }}
                                    // InputAdornmentProps={{ position: 'start' }}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                        if (newValue?.isBefore(startDate, 'day')) {
                                            setStartDate(null);
                                        }
                                    }}
                                    renderInput={(params) => <TextField
                                        variant="filled"
                                        {...params}
                                    />}
                                />
                            </Stack>
                            <div style={{ height: '6px', marginTop: '5px' }}>
                                {!!startDate && !!endDate && (startDateIsNotValid || endDateIsNotValid) && (
                                    <p className="report-error-range">Incorrect date range</p>
                                )}
                            </div>
                        </Stack>

                        <Stack direction="row" spacing={2}>
                            <Autocomplete
                                disablePortal
                                id="client"
                                sx={{ width: 300 }}
                                value={clientMemoizedValue || null}
                                onChange={(event: any, newValue: any) => {
                                    onChangeClient(newValue);
                                }}
                                className="input"
                                options={clientList}
                                renderInput={(params) => <TextField variant="filled"
                                    {...params}
                                    InputProps={{ disableUnderline: true, ...params.InputProps }}
                                    label="Client" />}
                            />
                            <Stack>


                                <Autocomplete
                                    disablePortal
                                    disabled={!currentClientId || countrySiteLoading}
                                    id="country-site"
                                    sx={{ width: 300 }}
                                    className="input"
                                    value={countrySiteMemoizedValue || null}
                                    onChange={(event: any, newValue: any) => {
                                        onChangeCountrySite(newValue);
                                    }}
                                    options={countrySiteList}
                                    renderInput={(params) => <TextField
                                        variant="filled"
                                        {...params}
                                        InputProps={{ disableUnderline: true, ...params.InputProps }}
                                        label="Country/Site" />}
                                />
                                {countrySiteLoading && <AutoCompleteLoading />}

                            </Stack>
                            <Stack>
                                <Autocomplete
                                    disablePortal
                                    disabled={!currentClientId || !currentCountrySite || lobSubLobLoading}
                                    sx={{ width: 300 }}
                                    id="lob-sub-lob"
                                    options={lobSubLobList}
                                    className="input"
                                    value={lobSubLobMemoizedValue || null}
                                    onChange={(event: any, newValue: any) => {
                                        onChangeLobSubLob(newValue);
                                    }}
                                    renderInput={(params) => <TextField
                                        variant="filled"
                                        {...params}
                                        InputProps={{ disableUnderline: true, ...params.InputProps }}
                                        label="Lob/Sub-LOB" />}
                                />
                                {lobSubLobLoading && <AutoCompleteLoading />}
                            </Stack>

                        </Stack>
                        <Stack>
                            <div className="radio-container">
                                <span className="radio checked">
                                </span>
                                Export Excel
                            </div>

                        </Stack>
                        <Stack style={{ alignItems: 'flex-end' }}>
                            <LoadingButton
                                loading={downloadLoading}
                                onClick={() => {
                                    setDownloadLoading(true);
                                    setDownloadPercentage(0);
                                    downloadReportAPI({
                                        "fileType": "excel",
                                        "reportId": Number(id),
                                        "clientId": currentClientId,
                                        // "countryId": currentCountrySite?.countryId,
                                        "siteId": currentCountrySite?.id,
                                        // "lobId": currentLobSubLob?.lobId,
                                        "subLobId": currentLobSubLob?.id,
                                        "startDate": startDate?.format('YYYY-MM-DDTHH:mm:ss.ms[Z]'), //"2022-12-09T19:13:55.872Z",
                                        "endDate": endDate?.format('YYYY-MM-DDTHH:mm:ss.ms[Z]'), // "2022-12-09T19:13:55.872Z"
                                    }, (progressEvent: any) => {
                                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                        setDownloadPercentage(percentCompleted);
                                    }).then(blob => {
                                        const url = window.URL.createObjectURL(blob);
                                        const a = document.createElement('a');
                                        a.href = url;
                                        a.download = `${heading}s_doe_${moment(moment().tz("Europe/Berlin")).format('YYYYMMDDHHmmss')}.xlsx`;
                                        document.body.appendChild(a);
                                        a.click();
                                        a.remove();
                                    }).catch(() => {
                                        setDownloadError(true);
                                    }).finally(() => {
                                        setDownloadLoading(false);
                                    });
                                }}
                                disabled={disabledExport}
                                style={{ width: '200px' }} disableRipple className="btn-default">
                                Export
                            </LoadingButton>
                        </Stack>

                        {
                            downloadLoading && <Stack>
                                <LinearProgress variant="determinate" value={downloadPercentage} />
                            </Stack>
                        }

                        {maxNumberOfRecords && <Stack>
                            <p className="info-text">
                                The maximum number of evaluations is limited to {maxNumberOfRecords}
                            </p>
                        </Stack>}
                    </Stack>

                </div>
            </Card>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={downloadError}
                autoHideDuration={3000}
                onClose={() => setDownloadError(false)}
                key={0}
            >
                <Alert elevation={6} variant="filled" onClose={() => setDownloadError(false)} severity="error" sx={{ width: '100%' }}>
                    Something went wrong!
                </Alert>
            </Snackbar>

        </>
    );

    return (
        <div className="rise-report-body">
            <div className="rise-report-content">
                {pageLoading ? (
                    <div style={{ paddingTop: '30px' }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {Data}
                    </>
                )}
            </div>

        </div>
    );
};

export default RiseReport;