import OktaAuth, { AccessToken, TokenManagerInterface } from "@okta/okta-auth-js";
import axios, { ResponseType } from "axios";
import { oktaAuthConfig } from "../config/oktaAuthConfig";

const authClient: OktaAuth = new OktaAuth(oktaAuthConfig);
const tokenManager: TokenManagerInterface = authClient.tokenManager;
// const idToken: IDToken = await tokenManager.get('idToken') as IDToken;
// const userInfo: UserClaims = await authClient.token.getUserInfo(accessToken, idToken);


export default async function request(
    url: string,
    params: { method: string, data?: any, responseType?: ResponseType, onDownloadProgress?: any },
    contentType = 'application/json'
): Promise<any> {
    const token = await tokenManager.get('accessToken') as AccessToken;

    return axios({
        url: `${process.env.REACT_APP_BASE_URL}${url}`,
        method: params.method,
        data: params.data,
        responseType: params.responseType || 'json',
        onDownloadProgress: params.onDownloadProgress,
        headers: {
            'Content-Type': contentType,
            ...(token?.accessToken ? { 'Authorization': `Bearer ${token?.accessToken}` } : {}),
        },
    })
        .then(function (response) {
            return Promise.resolve(response.data as any);
        }).catch(e => {
            if (e?.response?.status === 401) {
                authClient.revokeAccessToken().then((c: any) => { }).finally(() => {
                    window.location.reload();
                });
            }
            return Promise.reject(e.json());
        });
}
